exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landingtest-1-js": () => import("./../../../src/pages/landingtest1.js" /* webpackChunkName: "component---src-pages-landingtest-1-js" */),
  "component---src-pages-lv-js": () => import("./../../../src/pages/lv.js" /* webpackChunkName: "component---src-pages-lv-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-technologies-js": () => import("./../../../src/pages/technologies.js" /* webpackChunkName: "component---src-pages-technologies-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

